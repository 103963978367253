import {
    ApiResponse,
    getAxiosClient,
    makeApiRequest,
    TimeFrameValue,
    toFormData,
} from '@givelify/utils';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { MESSAGE_FILTER_VALUE } from '../../../models';
import { DoneesEndpoints } from './doneesEndpoints';
import { IDoneeService } from './IDoneesService';
import {
    toFaithLeaderPayload,
    UpdateBankInfoRequest,
    UpdateFaithLeaderRequest,
    UpdateImageRequest,
    UpdateMailingAddressRequest,
    UpdateOrganizationAddressRequest,
    UpdateOrganizationInfoRequest,
    UpdatePhysicalAddressRequest,
    UpdatePrimaryRepRequest,
    UpdateDonorRequest,
    UpdateSocialInfoRequest,
    UpdateTaxIdRequest,
    UpdateMissionStatementAndOrgLogoRequest,
    UpdateAppProfileRequest,
    UpdateCoveredFeesSettingsRequest,
} from './requests';
import {
    GetBankAccountsResponse,
    GetDepositsResponse,
    GetDoneeResponse,
    GetGivingLinkResponse,
    GetQuickGiveResponse,
    GetRefundedDonationsResponse,
    UpdateBankInfoResponse,
    UpdateDoneeResponse,
    UpdateDonorResponse,
} from './responses';

export class DoneesService implements IDoneeService {
    public async getCurrentDonee(
        config?: AxiosRequestConfig<any> | undefined,
    ): Promise<ApiResponse<GetDoneeResponse>> {
        const url = DoneesEndpoints.donee();
        const httpRequest = getAxiosClient().get(url, config);
        const result = await makeApiRequest<GetDoneeResponse>(httpRequest);
        return result;
    }

    public async getBankAccounts(
        doneeId: number,
    ): Promise<ApiResponse<GetBankAccountsResponse>> {
        const url = DoneesEndpoints.bankAccounts(doneeId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetBankAccountsResponse>(
            httpRequest,
        );
        return result;
    }

    public async updateBankInfo(
        doneeId: number,
        payload: UpdateBankInfoRequest,
    ): Promise<ApiResponse<UpdateBankInfoResponse>> {
        const url = DoneesEndpoints.bankInfo(doneeId);
        const formData = toFormData(payload);
        const httpRequest = getAxiosClient().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = makeApiRequest<UpdateBankInfoResponse>(httpRequest);
        return result;
    }

    public async updateDonor(
        doneeId: number,
        donorId: string,
        payload: Partial<UpdateDonorRequest>,
    ): Promise<ApiResponse<UpdateDonorResponse>> {
        const url = DoneesEndpoints.donor(doneeId, donorId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = makeApiRequest<UpdateDonorResponse>(httpRequest);
        return result;
    }

    public async updatePrimaryRepresentative(
        doneeId: number,
        payload: UpdatePrimaryRepRequest,
    ): Promise<ApiResponse<unknown>> {
        const url = DoneesEndpoints.beneficiary(doneeId);
        const formData = toFormData(payload);
        const httpRequest = getAxiosClient().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = makeApiRequest<unknown>(httpRequest);
        return result;
    }

    public async getQuickGive(
        doneeId: number,
    ): Promise<ApiResponse<GetQuickGiveResponse>> {
        const url = DoneesEndpoints.quickGive(doneeId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetQuickGiveResponse>(httpRequest);
        return result;
    }

    public async updateQuickGive(
        doneeId: number,
        payload: any,
    ): Promise<ApiResponse<unknown>> {
        const url = DoneesEndpoints.quickGive(doneeId);
        const httpRequest = getAxiosClient().put(url, payload);
        const result = await makeApiRequest<unknown>(httpRequest);
        return result;
    }

    public async getRefundedDonations(
        doneeId: number,
        timeFrame: TimeFrameValue,
        envelopeIds: number[],
        pageNumber: number,
        messageFilter?: MESSAGE_FILTER_VALUE[],
    ): Promise<ApiResponse<GetRefundedDonationsResponse>> {
        const url = DoneesEndpoints.donationsRefunded(
            doneeId,
            timeFrame,
            envelopeIds,
            pageNumber,
            messageFilter,
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetRefundedDonationsResponse>(
            httpRequest,
        );
        return result;
    }

    public async getDeposits(
        doneeId: number,
        timeFrame: TimeFrameValue,
        transactionFilter: any,
        onlyDate?: boolean,
        pageNumber?: number,
    ): Promise<ApiResponse<GetDepositsResponse>> {
        const url = DoneesEndpoints.deposits(
            doneeId,
            timeFrame,
            transactionFilter,
            onlyDate,
            pageNumber,
        );
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetDepositsResponse>(httpRequest);
        return result;
    }

    public async updateOrganizationLogo(
        doneeId: number,
        payload: UpdateImageRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            logo: payload.url || null,
            logoCroppedCoordinates: payload.croppedCoordinates,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateOrganizationBanner(
        doneeId: number,
        payload: UpdateImageRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            photo: payload.url || null,
            photoCroppedCoordinates: payload.croppedCoordinates,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateOrganizationAddress(
        doneeId: number,
        payload: UpdateOrganizationAddressRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateTaxId(
        doneeId: number,
        payload: UpdateTaxIdRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const formData = toFormData(payload);
        formData.append('_method', 'PATCH');
        const httpRequest = getAxiosClient().post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateSocialInfo(
        doneeId: number,
        payload: UpdateSocialInfoRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateMailingAddress(
        doneeId: number,
        payload: UpdateMailingAddressRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            mailingAddress: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updatePhysicalAddress(
        doneeId: number,
        payload: UpdatePhysicalAddressRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            physicalAddress: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateReceiptState(
        doneeId: number,
        payload: boolean,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            customReceipt: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateMissionStatement(
        doneeId: number,
        payload: string,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            missionStatement: payload,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateMissionStatementAndOrgLogo(
        doneeId: number,
        payload: UpdateMissionStatementAndOrgLogoRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateOrganizationInfo(
        doneeId: number,
        payload: UpdateOrganizationInfoRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateFaithLeader(
        doneeId: number,
        payload: UpdateFaithLeaderRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.faithLeader(doneeId);
        let httpRequest: Promise<AxiosResponse>;
        if (payload.doNotDisplayFaithLeader) {
            httpRequest = getAxiosClient().delete(url);
        } else {
            httpRequest = getAxiosClient().put(
                url,
                toFaithLeaderPayload(payload.data),
            );
        }
        const result = makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateDoneeFaithLeader(
        doneeId: number,
        userId: number,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, {
            faithLeaderId: userId,
        });
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async updateAppProfile(
        doneeId: number,
        payload: UpdateAppProfileRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.faithLeader(doneeId);
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }

    public async getGivingLink(
        doneeId: number,
    ): Promise<ApiResponse<GetGivingLinkResponse>> {
        const url = DoneesEndpoints.givingLink(doneeId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetGivingLinkResponse>(httpRequest);
        return result;
    }

    public async updateCoveredFeesSettings(
        doneeId: number,
        payload: UpdateCoveredFeesSettingsRequest,
    ): Promise<ApiResponse<UpdateDoneeResponse>> {
        const url = DoneesEndpoints.donees(doneeId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateDoneeResponse>(httpRequest);
        return result;
    }
}
