import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { EnvelopesQuery } from '../../../queries';
import { EnvelopesEndpoints } from './envelopesEndpoints';
import { IEnvelopesService } from './IEnvelopesService';
import { GetEnvelopesResponse } from './responses';

export class EnvelopesService implements IEnvelopesService {
    public async getEnvelopesData(
        doneeId: number,
        query: EnvelopesQuery,
    ): Promise<ApiResponse<GetEnvelopesResponse>> {
        const url = EnvelopesEndpoints.data(doneeId, query);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEnvelopesResponse>(httpRequest);
        return result;
    }
}
