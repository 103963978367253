import { lazy } from 'react';

type DynamicImport<T> = () => Promise<{ default: React.ComponentType<T> }>;
type LazyComponent<T> = React.LazyExoticComponent<React.ComponentType<T>>;

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const importRetry = async <T>(
    importFn: DynamicImport<T>,
    retries = 2,
    interval = 1000,
): Promise<{ default: React.ComponentType<T> }> => {
    try {
        return await importFn();
    } catch (error) {
        if (retries) {
            await wait(interval);
            return importRetry(importFn, retries - 1, interval);
        } else {
            throw error;
        }
    }
};

export const lazyWithRetry = <T>(
    componentImport: DynamicImport<T>,
    retries?: number,
    interval?: number,
): LazyComponent<T> =>
    lazy(() => importRetry(componentImport, retries, interval));
