import React from 'react';
import { getDateRangeHash } from '../dateUtils';
import { TimeFrameValue } from '../timeFrameUtils';

export const useTimeFrameEffect = (
    callback: React.EffectCallback,
    timeFrame: TimeFrameValue,
    dependencies: React.DependencyList,
) => {
    const prevStateRef = React.useRef<{
        hash: string | null;
        deps: React.DependencyList;
    }>({
        hash: null,
        deps: dependencies,
    });

    React.useEffect(() => {
        const currentHash = timeFrame ? getDateRangeHash(timeFrame) : null;
        const hasTimeFrameChanged = currentHash !== prevStateRef.current.hash;

        const hasOtherDependencyChanged = dependencies.some(
            (dep, index) => dep !== prevStateRef.current.deps[index],
        );

        if (hasTimeFrameChanged || hasOtherDependencyChanged) {
            prevStateRef.current = {
                hash: currentHash,
                deps: dependencies,
            };
            return callback();
        }
    }, [timeFrame, callback, dependencies]);
};
