import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { AuthEndpoints } from './authEndpoints';
import { IAuthService } from './IAuthService';
import {
    ForgotPasswordRequest,
    LoginAdminRequest,
    LoginRequest,
    ResetPasswordRequest,
} from './requests';
import { LoginResponse } from './responses';

export class AuthService implements IAuthService {
    public async login(
        payload: LoginRequest,
    ): Promise<ApiResponse<LoginResponse>> {
        const url = AuthEndpoints.login();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<LoginResponse>(httpRequest);
        return result;
    }

    public async loginAdmin(
        payload: LoginAdminRequest,
    ): Promise<ApiResponse<LoginResponse>> {
        const url = AuthEndpoints.adminLogin();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<LoginResponse>(httpRequest);
        return result;
    }

    public async logout(): Promise<ApiResponse<void>> {
        const url = AuthEndpoints.logout();
        const httpRequest = getAxiosClient().post(url);
        const result = await makeApiRequest<void>(httpRequest);
        return result;
    }

    public async forgotPassword(
        payload: ForgotPasswordRequest,
    ): Promise<ApiResponse<void>> {
        const url = AuthEndpoints.forgotPassowrd();
        const httpRequest = getAxiosClient().post(url, payload);
        const result = await makeApiRequest<void>(httpRequest);
        return result;
    }

    public async resetPassword(
        payload: ResetPasswordRequest,
    ): Promise<ApiResponse<void>> {
        const url = AuthEndpoints.resetPassword();
        const httpRequest = getAxiosClient().put(url, payload);
        const result = await makeApiRequest<void>(httpRequest);
        return result;
    }
}
