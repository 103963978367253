import { breakpoints } from '@givelify/ui/constants';
import {
    clearUnauthorizedInterceptor,
    getAccessTokenLocalStorage,
    registerUnauthorizedInterceptor,
    TimeFrameValues,
    TimezoneManager,
} from '@givelify/utils';
import { getTimeframeFilterFromUrlParams } from 'api/hooks';
import {
    getDonationService,
    getDonorsService,
    getEnvelopesService,
} from 'api/utils/serviceProvider';
import { OverviewLoadedData } from 'pages/overview/types';
import {
    defer,
    LoaderFunction,
    LoaderFunctionArgs,
    redirect,
    Route,
} from 'react-router-dom';
import GetStore, { getDispatch } from 'store';
import { loadData } from 'store/system/thunks';
import { getRangeDateType } from 'utils/timeFrameUtils';
import { setAccessToken } from '../store/user/actions';
import {
    LaunchGivelithonAsync,
    RoutesWrapperAsync,
    AdminLoginAsync,
    AppProfileAsync,
    BankDepositsAsync,
    BankInfoAsync,
    CampusesElementAsync,
    CaptivePortalGuardAsync,
    DonationActivityAsync,
    DonationSummaryAsync,
    DonationsPageAsync,
    DonationsRootAsync,
    DonorProfileAsync,
    DonorsMainAsync,
    EditProfileAsync,
    EnvelopesAsync,
    FundraisingToolsRoutesAsync,
    GenerateReportsWrapperAsync,
    GivelithonAsync,
    GivelithonRoutesAsync,
    IntegrationContentAsync,
    IntegrationsAsync,
    IntegrationsRoutesAsync,
    LegacyReportDonationsAsync,
    LegacyReportsAsync,
    LoginAsync,
    MissionStatementAsync,
    NotFoundPageAsync,
    OrgInfoPageAsync,
    OverviewRoutesAsync,
    QuickGiveElementAsync,
    RefundsAsync,
    ReportAsync,
    ReportBankDepositsAsync,
    ReportDisbursementsAsync,
    ReportDonationsAsync,
    ReportDonationsByDonorsAsync,
    ReportDonationsByEnvelopeAsync,
    ReportDonationsLegacyAsync,
    ReportDonorsAsync,
    ReportHistoryAsync,
    ReportHistoryDownloadWrapperAsync,
    ReportsRouteAsync,
    ResetPasswordWrapperAsync,
    SecuredRoutesAsync,
    SettingsDashboardAsync,
    SettingsOfficersAsync,
    SettingsRoutesAsync,
    FundraisingToolsRoot,
    SnapToGive,
    OnlineGiving,
    GivelifyButtonPage,
    SocialMediaGiving,
    SocialMediaGivingItemsWrapper,
    FacebookGiving,
    TwitterGiving,
    InstagramGiving,
    YouTubeGiving,
    GivelifyButtonPublicPageAsync,
    DepositDownloadAsync,
    CoveredFeesAsync,
} from './dynamicImports';
import { getDoneeIdFromQuery } from './GasRouterProvider';
import { PATH } from './routes';
import { getUnauthorizedRedirectUrl, removeInvalidToken } from './utils';

export const DonorProfileRoutes = (
    <Route lazy={DonorProfileAsync} path={PATH.DONORS.PROFILE} />
);

export const authenticatedLoader = <T extends LoaderFunction>(
    load: T,
): LoaderFunction => {
    return (args: LoaderFunctionArgs) => {
        const token = getAccessTokenLocalStorage();

        if (!token) {
            const { request } = args;
            const { pathname, searchParams } = new URL(request.url);
            const redirectTo = getUnauthorizedRedirectUrl(
                pathname,
                `?${searchParams.toString()}`,
            );
            return redirect(redirectTo);
        }
        return load(args);
    };
};

export const loginLoader = () => {
    clearUnauthorizedInterceptor();
    return null;
};

export const OverviewLoader: LoaderFunction = authenticatedLoader(
    ({ request }) => {
        const { searchParams } = new URL(request.url);

        const timeFrame = getTimeframeFilterFromUrlParams(searchParams);

        const clientWidth = document.documentElement?.clientWidth;
        const isMobile = clientWidth <= breakpoints.mobile;

        const rangeDateType = getRangeDateType(timeFrame, isMobile);

        const doneeIdFromQuery = getDoneeIdFromQuery(searchParams);
        const userDoneeId = GetStore().getState().User.user.doneeId;

        // If we load campus data - use doneeId from query, otherwise from user
        const doneeId = doneeIdFromQuery || userDoneeId;

        const envelopesData = getEnvelopesService().getEnvelopesDataByTime(
            timeFrame,
            doneeId,
        );

        const donationsStatistics = getDonationService().getDonationsStatistic(
            doneeId,
            timeFrame.start,
            timeFrame.end,
            rangeDateType,
        );

        let _rangeDateType = rangeDateType;
        if (
            timeFrame.selector === 'today' ||
            timeFrame.selector === 'yesterday'
        ) {
            _rangeDateType = 'hourly';
        } else if (
            timeFrame.selector === 'custom' &&
            rangeDateType === 'weekly'
        ) {
            _rangeDateType = 'monthly';
        }

        const donationsStatisticsByEnvelopes =
            getEnvelopesService().getDonationsStatisticByEnvelopes(
                doneeId,
                timeFrame.start,
                timeFrame.end,
                _rangeDateType,
            );

        const lifetimeTimeFrame = TimeFrameValues['lifetime'];
        const donorsData = getDonorsService().getDonorsData(
            lifetimeTimeFrame,
            doneeId,
        );

        const navigatedFromOtherPage = !!GetStore().getState().Donee.donee;

        return {
            envelopesData,
            donationsStatistics,
            donationsStatisticsByEnvelopes,
            donorsData,
            navigatedFromOtherPage,
        } satisfies OverviewLoadedData;
    },
);

export const Routes = (
    <Route lazy={RoutesWrapperAsync}>
        <Route
            lazy={GivelifyButtonPublicPageAsync}
            path={PATH.GIVELIFY_BUTTON}
        />
        <Route lazy={ResetPasswordWrapperAsync} path={PATH.DONEE.RESET} />
        <Route
            lazy={AdminLoginAsync}
            loader={loginLoader}
            path={PATH.ADMIN.LOGIN}
        />
        <Route lazy={LoginAsync} loader={loginLoader} path={PATH.DONEE.LOGIN} />
        <Route lazy={DepositDownloadAsync} path={PATH.DEPOSIT_DOWNLOAD} />
        <Route lazy={GivelithonRoutesAsync}>
            <Route
                lazy={LaunchGivelithonAsync}
                path={PATH.FUNDRAISING_TOOLS.LAUNCH_GIVELITHON}
            />
        </Route>
        <Route
            lazy={SecuredRoutesAsync}
            loader={authenticatedLoader(({ request }) => {
                registerUnauthorizedInterceptor((token: string | null) => {
                    if (token) {
                        getDispatch()(setAccessToken(token));
                    } else {
                        removeInvalidToken();
                    }
                });

                const user = GetStore().getState().User.user;
                const timezone = user?.givingPartnerTimezone;
                if (timezone !== null) {
                    TimezoneManager.setDefault(timezone);
                }

                const { searchParams } = new URL(request.url);

                const doneeId = getDoneeIdFromQuery(searchParams);
                const dispatch = getDispatch();
                const result = dispatch(loadData(doneeId));
                return defer({ result });
            })}
            shouldRevalidate={() => false}
        >
            <Route
                lazy={OverviewRoutesAsync}
                loader={OverviewLoader}
                path={PATH.OVERVIEW}
            />
            <Route lazy={CaptivePortalGuardAsync}>
                <Route
                    lazy={BankDepositsAsync}
                    path={PATH.DONATIONS.BANK_DEPOSITS}
                />

                <Route lazy={RefundsAsync} path={PATH.DONATIONS.REFUNDS} />
                <Route lazy={DonationsRootAsync} path={PATH.DONATIONS.ROOT} />
                <Route lazy={DonationsPageAsync}>
                    <Route
                        lazy={DonationActivityAsync}
                        path={PATH.DONATIONS.DONATIONS_ACTIVITY}
                    />
                    <Route
                        lazy={DonationSummaryAsync}
                        path={PATH.DONATIONS.DONATION_SUMMARY.BY_ENVELOPES}
                    />
                    <Route
                        lazy={DonationSummaryAsync}
                        path={PATH.DONATIONS.DONATION_SUMMARY.BY_DATE}
                    />
                </Route>
                <Route lazy={DonorsMainAsync} path={PATH.DONORS.ROOT} />
                {DonorProfileRoutes}
                <Route lazy={ReportsRouteAsync}>
                    <Route
                        lazy={ReportAsync}
                        path={PATH.REPORTS.GENERATE_REPORTS}
                    />
                    <Route
                        lazy={LegacyReportsAsync}
                        path={PATH.REPORTS.LEGACY_REPORTS}
                    />
                    <Route
                        lazy={LegacyReportDonationsAsync}
                        path={PATH.REPORTS.LEGACY_REPORTS_DONATIONS}
                    />
                    <Route
                        lazy={LegacyReportDonationsAsync}
                        path={PATH.REPORTS.LEGACY_REPORTS_DISBURSMENTS}
                    />
                    <Route
                        lazy={LegacyReportDonationsAsync}
                        path={PATH.REPORTS.LEGACY_REPORTS_DONORS}
                    />
                    <Route
                        lazy={LegacyReportDonationsAsync}
                        path={PATH.REPORTS.LEGACY_REPORTS_IMPORT_FRIENDLY}
                    />
                    <Route
                        lazy={LegacyReportDonationsAsync}
                        path={PATH.REPORTS.LEGACY_REPORTS_BY_ENVELOPE}
                    />
                    <Route
                        lazy={LegacyReportDonationsAsync}
                        path={PATH.REPORTS.LEGACY_REPORTS_BY_DONOR}
                    />
                    <Route
                        lazy={ReportHistoryAsync}
                        path={PATH.REPORTS.REPORT_HISTORY}
                    />
                    <Route
                        lazy={ReportHistoryDownloadWrapperAsync}
                        path={PATH.REPORTS.REPORT_HISTORY_DOWNLOAD}
                    />
                    <Route lazy={GenerateReportsWrapperAsync}>
                        <Route
                            lazy={ReportDonationsAsync}
                            path={PATH.REPORTS.REPORT_DONATIONS}
                        />
                        <Route
                            lazy={ReportBankDepositsAsync}
                            path={PATH.REPORTS.REPORT_BANK_DEPOSITS}
                        />
                        <Route
                            lazy={ReportDonorsAsync}
                            path={PATH.REPORTS.REPORT_DONORS}
                        />
                        <Route
                            lazy={ReportDisbursementsAsync}
                            path={PATH.REPORTS.REPORT_DISBURSEMENTS}
                        />
                        <Route
                            lazy={ReportDonationsLegacyAsync}
                            path={PATH.REPORTS.REPORT_DONATIONS_LEGACY}
                        />
                        <Route
                            lazy={ReportDonationsByEnvelopeAsync}
                            path={PATH.REPORTS.DONATION_BY_ENVELOPE}
                        />
                        <Route
                            lazy={ReportDonationsByDonorsAsync}
                            path={PATH.REPORTS.DONATIONS_BY_DONOR}
                        />
                    </Route>
                </Route>
                <Route lazy={SettingsRoutesAsync}>
                    <Route
                        lazy={SettingsDashboardAsync}
                        path={PATH.SETTINGS.ROOT}
                    />
                    <Route
                        lazy={AppProfileAsync}
                        path={PATH.SETTINGS.APP_PROFILE}
                    />
                    <Route
                        lazy={QuickGiveElementAsync}
                        path={PATH.SETTINGS.QUICK_GIVE}
                    />
                    <Route
                        lazy={EnvelopesAsync}
                        path={PATH.SETTINGS.ENVELOPES}
                    />
                    <Route
                        lazy={SettingsOfficersAsync}
                        path={PATH.SETTINGS.USERS}
                    />
                    <Route
                        lazy={BankInfoAsync}
                        path={PATH.SETTINGS.BANK_INFO}
                    />
                    <Route
                        lazy={OrgInfoPageAsync}
                        path={PATH.SETTINGS.ORG_INFO}
                    />
                    <Route
                        lazy={CampusesElementAsync}
                        path={PATH.SETTINGS.CAMPUSES}
                    />
                    <Route
                        lazy={MissionStatementAsync}
                        path={PATH.SETTINGS.MISSION_STATEMENT}
                    />
                    <Route
                        lazy={CoveredFeesAsync}
                        path={PATH.SETTINGS.COVERED_FEES}
                    />
                </Route>
                <Route lazy={EditProfileAsync} path={PATH.EDIT_PROFILE} />
                <Route lazy={IntegrationsRoutesAsync}>
                    <Route
                        lazy={IntegrationContentAsync}
                        path={PATH.DATA.INTEGRATION_CONTENT}
                    />
                    <Route
                        lazy={IntegrationsAsync}
                        path={PATH.DATA.INTEGRATIONS_SETUP}
                    />
                </Route>
                <Route
                    lazy={GivelithonAsync}
                    path={PATH.FUNDRAISING_TOOLS.GIVELITHON}
                />
                <Route lazy={FundraisingToolsRoutesAsync}>
                    <Route
                        lazy={FundraisingToolsRoot}
                        path={PATH.FUNDRAISING_TOOLS.ROOT}
                    />
                    <Route
                        lazy={SnapToGive}
                        path={PATH.FUNDRAISING_TOOLS.SNAP_GIVE}
                    />
                    <Route
                        lazy={OnlineGiving}
                        path={PATH.FUNDRAISING_TOOLS.ONLINE_GIVING}
                    />
                    <Route
                        lazy={GivelifyButtonPage}
                        path={PATH.FUNDRAISING_TOOLS.GIVELIFY_BUTTON}
                    />
                    <Route
                        lazy={SocialMediaGiving}
                        path={PATH.FUNDRAISING_TOOLS.SOCIAL_MEDIA_GIVING}
                    />
                    <Route lazy={SocialMediaGivingItemsWrapper}>
                        <Route
                            lazy={FacebookGiving}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_FACEBOOK
                            }
                        />
                        <Route
                            lazy={TwitterGiving}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_TWITTER
                            }
                        />
                        <Route
                            lazy={InstagramGiving}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_INSTAGRAM
                            }
                        />
                        <Route
                            lazy={YouTubeGiving}
                            path={
                                PATH.FUNDRAISING_TOOLS
                                    .SOCIAL_MEDIA_GIVING_YOUTUBE
                            }
                        />
                    </Route>
                </Route>
            </Route>
            <Route lazy={NotFoundPageAsync} path="*" />
        </Route>
    </Route>
);

export default Routes;
