export const CommonEndpoints = {
    enabledFeatures: () => '/enabled-features',
    email: (token: string) => `/email?token=${token}`,
    titles: () => '/titles',
    irs: (taxId: string) => `/irs/${taxId}`,
    denominations: () => '/denominations',
    bankNames: (routingNumber: string) => `/bank-names/${routingNumber}`,
    sendButtonEmail: () => '/givelify-button/send-email',
    getIncompletePrimaryRepresentative: () => `/form-state`,
    saveIncompletePrimaryRepresentative: () => `/form-state`,
};
