import React from 'react';
import { lazyWithRetry } from '@givelify/utils';
import { Logger } from '@givelify/utils';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import FullStory from './fullStory/FullStory';

const Error500Lazy = lazyWithRetry(
    () => import('./theme/components/ErrorPage'),
);

type AppWrapperProps = {
    store: Store<unknown, AnyAction>;
    queryClient: QueryClient;
};

export const DataWrapper: React.FCC<AppWrapperProps> = ({
    store,
    queryClient,
    children,
}) => (
    <Logger.BasicErrorBoundary fallback={<Error500Lazy />}>
        <Provider stabilityCheck="never" store={store}>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>{children}</HelmetProvider>
                <FullStory />
            </QueryClientProvider>
        </Provider>
    </Logger.BasicErrorBoundary>
);

const AppWrapper: React.FCC<AppWrapperProps> = ({
    store,
    queryClient,
    children,
}) => (
    <DataWrapper queryClient={queryClient} store={store}>
        {children}
    </DataWrapper>
);

export default AppWrapper;
