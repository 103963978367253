import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { CommonEndpoints } from './commonEndpoints';
import { ICommonService } from './ICommonService';
import { SaveIncompletePrimaryRepRequest } from './requests';
import {
    AddDenominationsResponse,
    GetBankNameResponse,
    GetDenominationsResponse,
    GetEmailResponse,
    GetEnabledFeaturesResponse,
    GetIncompletePrimaryRepResponse,
    GetOfficerTitlesResponse,
    GetTaxIdDetailsResponse,
} from './responses';

export class CommonService implements ICommonService {
    public async getEnabledFeatures(): Promise<
        ApiResponse<GetEnabledFeaturesResponse>
    > {
        const url = CommonEndpoints.enabledFeatures();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEnabledFeaturesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getEmail(
        token: string,
    ): Promise<ApiResponse<GetEmailResponse>> {
        const url = CommonEndpoints.email(token);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetEmailResponse>(httpRequest);
        return result;
    }

    public async getOfficerTitles(): Promise<
        ApiResponse<GetOfficerTitlesResponse>
    > {
        const url = CommonEndpoints.titles();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetOfficerTitlesResponse>(
            httpRequest,
        );
        return result;
    }

    public async getTaxIdDetails(
        taxId: string,
    ): Promise<ApiResponse<GetTaxIdDetailsResponse>> {
        const url = CommonEndpoints.irs(taxId);
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetTaxIdDetailsResponse>(
            httpRequest,
        );
        return result;
    }

    public async getDenominations(): Promise<
        ApiResponse<GetDenominationsResponse>
    > {
        const url = CommonEndpoints.denominations();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetDenominationsResponse>(
            httpRequest,
        );
        return result;
    }

    public async addDenomination(
        name: string,
    ): Promise<ApiResponse<AddDenominationsResponse>> {
        const url = CommonEndpoints.denominations();
        const httpRequest = getAxiosClient().post(url, { name });
        const result = await makeApiRequest<AddDenominationsResponse>(
            httpRequest,
        );
        return result;
    }

    public async getBankName(
        routingNumber: string,
    ): Promise<ApiResponse<GetBankNameResponse>> {
        const url = CommonEndpoints.bankNames(routingNumber);
        const httpRequest = getAxiosClient().get<GetBankNameResponse>(url);
        const result = await makeApiRequest<GetBankNameResponse>(httpRequest);
        return result;
    }

    public async sendButtonEmail(
        recipientName: string,
        recipientEmail: string,
    ): Promise<ApiResponse<undefined>> {
        const url = CommonEndpoints.sendButtonEmail();
        const httpRequest = getAxiosClient().post(url, {
            recipientName,
            recipientEmail,
        });
        const result = await makeApiRequest<undefined>(httpRequest);
        return result;
    }

    public async getIncompletePrimaryRepresentative(): Promise<
        ApiResponse<GetIncompletePrimaryRepResponse>
    > {
        const url = CommonEndpoints.getIncompletePrimaryRepresentative();
        const httpRequest = getAxiosClient().get(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                formType: 'primary_representative',
            },
        });
        const result =
            makeApiRequest<GetIncompletePrimaryRepResponse>(httpRequest);
        return result;
    }

    public async saveIncompletePrimaryRepresentative(
        payload: Partial<SaveIncompletePrimaryRepRequest>,
    ): Promise<ApiResponse<unknown>> {
        const url = CommonEndpoints.saveIncompletePrimaryRepresentative();
        const httpRequest = getAxiosClient().post(
            url,
            {
                formType: 'primary_representative',
                formData: JSON.stringify(payload),
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        const result = await makeApiRequest<unknown>(httpRequest);
        return result;
    }
}
