import { convertToDate } from '@givelify/utils';
import {
    SetScheduledReport,
    ScheduledReportRequest,
    ScheduledReport,
    SET_SCHEDULED_REPORT,
} from './types';

export const setScheduledReport = (
    data: ScheduledReport,
): SetScheduledReport => {
    if (data) {
        localStorage.setItem(SET_SCHEDULED_REPORT, JSON.stringify(data));
    }
    return {
        type: SET_SCHEDULED_REPORT,
        data: {
            ...data,
            nextRun: data.nextRun ? convertToDate(data.nextRun) : null,
            lastRun: data.lastRun ? convertToDate(data.lastRun) : null,
            createdAt: data.createdAt ? convertToDate(data.createdAt) : null,
            updatedAt: data.updatedAt ? convertToDate(data.updatedAt) : null,
        },
    };
};

/* eslint-disable-next-line */
export const mapScheduledReportToRequest = (
    data: ScheduledReport,
): ScheduledReportRequest => ({
    name: data.name,
    id: data.id,
    donee_id: data.doneeId,
    frequency: data.frequency,
    scheduled_date: data.scheduledDate,
    scheduled_day: data.scheduledDay,
    receives_notifications: data.receivesNotifications,
    report_type: data.reportType,
    report_type_version: data.reportTypeVersion,
    next_run_date: data.nextRun ? data.nextRun.toISOString() : null,
    date_range_type: data.dateRangeType,
    match_by: data.matchBy,
});
