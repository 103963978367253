export const LaunchGivelithonAsync = () =>
    import('pages/givelithon/LaunchGivelithon').then((module) => ({
        Component: module.default,
    }));

export const RoutesWrapperAsync = () =>
    import('./RoutesWrapper').then(({ RoutesWrapper: Component }) => ({
        Component,
    }));

export const ResetPasswordWrapperAsync = async () =>
    import('pages/resetPassword/ResetPassword').then(
        ({ default: Component }) => ({
            Component,
        }),
    );
export const AdminLoginAsync = () =>
    import('pages/login/AdminLogin').then(({ AdminLogin }) => ({
        Component: AdminLogin,
    }));
export const LoginAsync = () =>
    import('pages/login/Login').then(({ default: Component }) => ({
        Component,
    }));

export const DepositDownloadAsync = () =>
    import('pages/depositDownload').then(({ DepositDownload }) => ({
        Component: DepositDownload,
    }));

export const CaptivePortalGuardAsync = () =>
    import('./CaptivePortalGuard').then(
        ({ CaptivePortalGuard: Component }) => ({
            Component,
        }),
    );

export const GivelithonRoutesAsync = () =>
    import('./GivelithonRoutes').then(({ default: Component }) => ({
        Component,
    }));

export const SecuredRoutesAsync = () =>
    import('./SecuredRoutes').then(({ default: Component }) => ({
        Component,
    }));

export const OverviewRoutesAsync = () =>
    import('./OverviewRoutes').then(({ default: Component }) => ({
        Component,
    }));

export const BankDepositsAsync = () =>
    import('pages/donations/BankDeposits').then(
        ({ BankDeposits: Component }) => ({
            Component,
        }),
    );

export const RefundsAsync = () =>
    import('pages/donations/Refunds').then(({ Refunds: Component }) => ({
        Component,
    }));

export const DonationsRootAsync = () =>
    import('pages/donations/DonationsRoot').then(
        ({ DonationsRoot: Component }) => ({
            Component,
        }),
    );

export const DonationActivityAsync = () =>
    import('pages/donations/donationActivity').then(
        ({ DonationActivity: Component }) => ({
            Component,
        }),
    );

export const DonationsPageAsync = () =>
    import('pages/donations/donationsPage').then(
        ({ DonationsPage: Component }) => ({
            Component,
        }),
    );

export const DonationSummaryAsync = () =>
    import('pages/donations/donationSummary').then(
        ({ DonationSummary: Component }) => ({
            Component,
        }),
    );

export const DonorsMainAsync = () =>
    import('pages/donors/DonorsMain').then(({ DonorsMain: Component }) => ({
        Component,
    }));

export const DonorProfileAsync = () =>
    import('pages/newDonorProfile').then(({ DonorProfile: Component }) => ({
        Component,
    }));

export const ReportsRouteAsync = () =>
    import('./ReportRoutes').then(({ default: Component }) => ({
        Component,
    }));

export const GenerateReportsWrapperAsync = () =>
    import('./ReportRoutes').then(({ GenerateReportsWrapper: Component }) => ({
        Component,
    }));

export const ReportAsync = () =>
    import('../pages/reports/GenerateReports').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const LegacyReportsAsync = () =>
    import('pages/reports/LegacyReports').then(({ default: Component }) => ({
        Component,
    }));

export const LegacyReportDonationsAsync = () =>
    import('pages/reports/GenerateReports/legacy/LegacyReportDonations').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const ReportHistoryAsync = () =>
    import('../pages/reports/ReportHistory').then(({ default: Component }) => ({
        Component,
    }));

export const ReportHistoryDownloadWrapperAsync = () =>
    import('../pages/reports/ReportHistory/ReportsHistoryDownloadWrapper').then(
        ({ ReportHistoryDownloadWrapper: Component }) => ({
            Component,
        }),
    );

export const ReportDonationsAsync = () =>
    import('../pages/reports/GenerateReports/report/ReportDonations').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const ReportBankDepositsAsync = () =>
    import('../pages/reports/GenerateReports/report/ReportBankDeposits').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const ReportDonorsAsync = () =>
    import('../pages/reports/GenerateReports/report/ReportDonors').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const ReportDisbursementsAsync = () =>
    import('../pages/reports/GenerateReports/report/ReportDisbursements').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const ReportDonationsLegacyAsync = () =>
    import(
        '../pages/reports/GenerateReports/report/ReportDonationsLegacy'
    ).then(({ default: Component }) => ({
        Component,
    }));

export const ReportDonationsByEnvelopeAsync = () =>
    import(
        '../pages/reports/GenerateReports/report/ReportDonationsByEnvelope'
    ).then(({ default: Component }) => ({
        Component,
    }));

export const ReportDonationsByDonorsAsync = () =>
    import(
        '../pages/reports/GenerateReports/report/ReportDonationsByDonors'
    ).then(({ default: Component }) => ({
        Component,
    }));

export const SettingsRoutesAsync = () =>
    import('./SettingsRoutes').then(({ SettingsRoutes: Component }) => ({
        Component,
    }));

export const CampusesElementAsync = () =>
    import('./SettingsRoutes').then(({ CampusesElement: Component }) => ({
        Component,
    }));

export const QuickGiveElementAsync = () =>
    import('./SettingsRoutes').then(({ QuickGiveElement: Component }) => ({
        Component,
    }));

export const OrgInfoPageAsync = () =>
    import('../pages/settings/about').then(({ OrgInfoPage: Component }) => ({
        Component,
    }));

export const AppProfileAsync = () =>
    import('../pages/settings/appProfile').then(
        ({ AppProfile: Component }) => ({
            Component,
        }),
    );
export const BankInfoAsync = () =>
    import('../pages/settings/banking').then(({ BankInfo: Component }) => ({
        Component,
    }));

export const SettingsDashboardAsync = () =>
    import('../pages/settings/dashboard').then(
        ({ SettingsDashboard: Component }) => ({
            Component,
        }),
    );
export const EnvelopesAsync = () =>
    import('../pages/settings/envelopes').then(({ default: Component }) => ({
        Component,
    }));
export const MissionStatementAsync = () =>
    import('../pages/settings/mission').then(
        ({ MissionStatement: Component }) => ({
            Component,
        }),
    );
export const CoveredFeesAsync = () =>
    import('./SettingsRoutes').then(({ CoveredFeesElement }) => ({
        Component: CoveredFeesElement,
    }));
export const SettingsOfficersAsync = () =>
    import('../pages/settings/officers').then(
        ({ SettingsOfficers: Component }) => ({
            Component,
        }),
    );

export const EditProfileAsync = () =>
    import('pages/editProfile/EditProfile').then(
        ({ EditProfile: Component }) => ({
            Component,
        }),
    );

export const IntegrationsRoutesAsync = () =>
    import('./IntegrationsRoutes').then(({ default: Component }) => ({
        Component,
    }));

export const IntegrationContentAsync = () =>
    import('pages/integrations/IntegrationContent').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const IntegrationsAsync = () =>
    import('pages/integrations/IntegrationSetup').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const GivelithonAsync = () =>
    import('pages/givelithon/Givelithon').then(({ default: Component }) => ({
        Component,
    }));

export const FundraisingToolsRoutesAsync = () =>
    import('./FundraisingTools/FundraisingToolsRoutes').then(
        ({ default: Component }) => ({
            Component,
        }),
    );

export const NotFoundPageAsync = () =>
    import('pages/NotFound').then(({ default: Component }) => ({
        Component,
    }));

export const SocialMediaGiving = () =>
    import('./FundraisingToolsRoutes').then(
        ({ SocialMediaGiving: Component }) => ({
            Component,
        }),
    );
export const SocialMediaGivingItemsWrapper = () =>
    import('./FundraisingToolsRoutes').then(
        ({ SocialMediaGivingItemsWrapper: Component }) => ({
            Component,
        }),
    );
export const FacebookGiving = () =>
    import('./FundraisingToolsRoutes').then(
        ({ FacebookGiving: Component }) => ({
            Component,
        }),
    );
export const InstagramGiving = () =>
    import('./FundraisingToolsRoutes').then(
        ({ InstagramGiving: Component }) => ({
            Component,
        }),
    );
export const TwitterGiving = () =>
    import('./FundraisingToolsRoutes').then(({ TwitterGiving: Component }) => ({
        Component,
    }));
export const YouTubeGiving = () =>
    import('./FundraisingToolsRoutes').then(({ YouTubeGiving: Component }) => ({
        Component,
    }));

export const GivelifyButtonPage = () =>
    import('./FundraisingToolsRoutes').then(
        ({ GivelifyButtonPage: Component }) => ({
            Component,
        }),
    );

export const GivelifyButtonPublicPageAsync = () =>
    import('./FundraisingToolsRoutes').then(
        ({ GivelifyButtonPublicPage: Component }) => ({
            Component,
        }),
    );

export const FundraisingToolsRoot = () =>
    import('./FundraisingToolsRoutes').then(
        ({ FundraisingToolsRoot: Component }) => ({
            Component,
        }),
    );

export const SnapToGive = () =>
    import('./FundraisingToolsRoutes').then(({ SnapToGive: Component }) => ({
        Component,
    }));

export const OnlineGiving = () =>
    import('./FundraisingToolsRoutes').then(({ OnlineGiving: Component }) => ({
        Component,
    }));
