import { Logger, initTracking } from '@givelify/utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import { QueryClient } from 'react-query';
import {
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { Routes } from './router';
import * as serviceWorker from './serviceWorkerRegistration';
import getStore from './store';
import { webConfig } from './webConfig';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(minMax);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

console.log(webConfig.appVersion);

if (webConfig.environment === 'development') {
    import('./mockSetup');
}

if (webConfig.environment !== 'development') {
    Logger.configure({
        service: 'donee-portal-ui',
        logUnhandledExceptions: true,
        userType: Logger.UserType.GIVING_PARTNER,
    });

    initTracking({
        environment: webConfig.environment,
        clevertapProps: {
            clevertapAccountId: webConfig.clevertapAccountId,
        },
        datadogProps: {
            appVersion: webConfig.appVersion,
            datadogRumApplicationId: webConfig.datadogRumApplicationId,
            datadogRumClientToken: webConfig.datadogRumClientToken,
            datadogRumService: webConfig.datadogRumService,
        },
        sentryProps: {
            sentryUrl: webConfig.sentryUrl,
        },
        mixpanelProps: {
            mixpanelToken: webConfig.mixpanelToken,
        },
    });
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: 0,
            cacheTime: 1000 * 60,
        },
    },
});

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={
                <AppWrapper queryClient={queryClient} store={getStore()}>
                    <ScrollRestoration />
                    <Outlet />
                </AppWrapper>
            }
        >
            {Routes}
        </Route>,
    ),
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
