export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    smallMobile: 500,
    mobile: 768,
    smallTablet: 1025,
    tablet: 1280,
};
