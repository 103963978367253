import { onLogOut } from '@givelify/utils';
import { setAccessToken } from 'store/user/actions';
import { getDispatch } from '../store';
import { PATH } from './routes';

export const getUnauthorizedRedirectUrl = (
    pathname: string,
    search: string,
) => {
    const returnUrl = pathname + search;
    const redirectTo = returnUrl
        ? `${PATH.DONEE.LOGIN}?returnUrl=${encodeURIComponent(returnUrl)}`
        : PATH.DONEE.LOGIN;

    return redirectTo;
};

export const removeInvalidToken = () => {
    getDispatch()(setAccessToken(undefined));
    onLogOut();
};
