import { ApiResponse, getAxiosClient, makeApiRequest } from '@givelify/utils';
import { IUsersService } from './IUsersService';
import { UpdateUserRequest } from './requests';
import { GetCurrentUserResponse, UpdateUserResponse } from './responses';
import { UsersEndpoints } from './usersEndpoints';

export class UsersService implements IUsersService {
    public async getCurrentUser(): Promise<
        ApiResponse<GetCurrentUserResponse>
    > {
        const url = UsersEndpoints.user();
        const httpRequest = getAxiosClient().get(url);
        const result = await makeApiRequest<GetCurrentUserResponse>(
            httpRequest,
        );
        return result;
    }

    public async updateUser(
        userId: number,
        payload: UpdateUserRequest,
    ): Promise<ApiResponse<UpdateUserResponse>> {
        const url = UsersEndpoints.users(userId);
        const httpRequest = getAxiosClient().patch(url, payload);
        const result = await makeApiRequest<UpdateUserResponse>(httpRequest);
        return result;
    }
}
