import url from 'url';

// Note: NODE_ENV does not represent which environment we are running the code in.
// Setting NODE_ENV=production enables caching of templates, css and less verbose error messages.
const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
const apiUrl = process.env.REACT_APP_API_BASE_URL;
const givelithonApiBaseUrl = process.env.REACT_APP_GIVELITHON_API_BASE_URL;
const siteUrl = process.env.REACT_APP_SITE_URL;

// TODO: Remove these test values after the env vars are available on QA
const givelithonApiSecret =
    process.env.REACT_APP_GIVELITHON_API_SECRET || 'test.secret';
const givelithonApiToken =
    process.env.REACT_APP_GIVELITHON_API_TOKEN || 'test.token';

if (!apiUrl) throw new Error('REACT_APP_API_BASE_URL is not set');
if (!givelithonApiBaseUrl && !process.env.STORYBOOK)
    throw new Error('REACT_APP_GIVELITHON_API_BASE_URL is not set');

const PlanningCenterConfig = {
    clientId: process.env.REACT_APP_PLANNING_CENTER_CLIENT_ID || '',
    redirectURI: process.env.REACT_APP_PLANNING_CENTER_REDIRECT_URI || '',
    scope: process.env.REACT_APP_PLANNING_CENTER_SCOPE || '',
};

// We want to let users to skip onboarding in some cases
// We dont know donee ids so cant just disable split flags
// That's why we introduce this search param
// It will be shared only with particular users
let skipOnboarding = false;
try {
    const urlParams = new URLSearchParams(window?.location?.search);
    skipOnboarding = urlParams.has('skipOnboarding');
    // eslint-disable-next-line
} catch {}

export const webConfig = {
    appVersion:
        environment === 'production' || environment === 'demo'
            ? process.env.REACT_APP_VERSION
            : `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_COMMIT_HASH}`,
    environment,
    isDev: environment === 'development',
    isProd: environment === 'production',
    apiUrl,
    baseUrl: url.resolve(apiUrl, 'api'),
    siteUrl,
    legacyDashboardSignInUrl: process.env.REACT_APP_LEGACY_DASHBOARD_SIGNIN_URL,
    givelithonApiBaseUrl,
    givelithonApiSecret,
    givelithonApiToken,
    sentryUrl: process.env.REACT_APP_SENTRY_URL,
    clevertapAccountId: process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID,
    datadogRumApplicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    datadogRumClientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    datadogRumService: process.env.REACT_APP_DATADOG_RUM_SERVICE,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    supportUrl: 'https://support.givelify.com',
    signupUrl: process.env.REACT_APP_SIGNUP_URL,
    termsAndConditionsUrl: process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
    searchEngineUrl: process.env.REACT_APP_SEARCH_ENGINE_URL,
    searchEngineWsUrl: process.env.REACT_APP_SEARCH_ENGINE_WS_URL,
    searchEngineWsToken: process.env.REACT_APP_SEARCH_ENGINE_WS_TOKEN,
    ggPlayStoreUrl:
        'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&hl=en_US&gl=US&pli=1',
    appStoreUrl:
        'https://apps.apple.com/us/app/givelify-mobile-giving-app/id725815127',
    mockEnabled: process.env.REACT_APP_ENABLE_MOCK === 'true',
    googleSiteKey: process.env.REACT_APP_GOOGLE_SITE_KEY,
    skipOnboarding,
    supportChurchGivingTuesday:
        'https://www.givelify.com/blog/givingtuesday-church-ideas/',
    supportNonprofitGivingTuesday:
        'https://www.givelify.com/blog/givingtuesday-nonprofit-fundraising/',
    supportChurchGivingTuesdayToolkit:
        'https://www.givelify.com/givingtuesday/churches/',
    supportNonprofitGivingTuesdayToolkit:
        'https://www.givelify.com/givingtuesday/nonprofits/',
    planningCenterAuthURL: `https://api.planningcenteronline.com/oauth/authorize?client_id=${PlanningCenterConfig.clientId}&redirect_uri=${PlanningCenterConfig.redirectURI}&response_type=code&scope=${PlanningCenterConfig.scope}`,
    calendlyUrl: process.env.REACT_APP_CALENDLY_URL,
    flagRepository: 'https://www.givelify.com/data/country-flags/',
    supportCoveredFees:
        'https://www.nextafter.com/experiments/how-introducing-donor-fees-impacts-conversion/',
};
