import { Logger } from '../logger';
import { LogLevel } from '../logger/models';
import { ClevertapDonee, ClevertapUser, EnabledFeature } from './types';

const errMsg = 'Tracking context is not initialized';

export const isTest = process.env.NODE_ENV === 'test';

// See datadog website for details
const featureFlagNameInvalidCharacters = [
    '.',
    ':',
    '+',
    '-',
    '=',
    '&&',
    '||',
    '>',
    '<',
    '!',
    '(',
    ')',
    '{',
    '}',
    '[',
    ']',
    '^',
    '"',
    '“',
    '”',
    '~',
    '*',
    '?',
    '\\',
];

const getDatadog = () => import('@datadog/browser-rum');
const getClevertap = () => import('clevertap-web-sdk');
const getMixpanel = () => import('mixpanel-browser');
const getSentry = () => import('@sentry/react');

type SentryProps = {
    sentryUrl: string;
};
type ClevertapProps = {
    clevertapAccountId: string;
};
type DatadogProps = {
    datadogRumApplicationId: string;
    datadogRumClientToken: string;
    datadogRumService: string;
    appVersion: string;
};
type MixpanelProps = {
    mixpanelToken: string;
};

type InitProps = {
    environment: string;
    sentryProps: SentryProps;
    clevertapProps: ClevertapProps;
    datadogProps: DatadogProps;
    mixpanelProps: MixpanelProps;
};

export const initTracking = (props: InitProps) => {
    initSentry(props.environment, props.sentryProps);
    initClevertap(props.clevertapProps);
    initDatadog(props.environment, props.datadogProps);
    initMixpanel(props.mixpanelProps);
};

const initSentry = async (environment: string, sentryProps: SentryProps) => {
    const Sentry = await getSentry();
    Sentry.init({
        environment: environment,
        dsn: sentryProps.sentryUrl,
    });
};

const initClevertap = async (props: ClevertapProps) => {
    const { default: clevertap } = await getClevertap();
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: false });
    clevertap.init(props.clevertapAccountId);
    clevertap.spa = true;
};

const initDatadog = async (environment: string, props: DatadogProps) => {
    const { datadogRum } = await getDatadog();

    datadogRum.init({
        applicationId: props.datadogRumApplicationId,
        clientToken: props.datadogRumClientToken,
        site: 'datadoghq.com',
        service: props.datadogRumService,
        env: environment,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: props.appVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['feature_flags'],
    });
};

const initMixpanel = async (props: MixpanelProps) => {
    const { default: mixpanel } = await getMixpanel();

    mixpanel.init(props.mixpanelToken, {
        debug: false,
        track_pageview: false,
        persistence: 'localStorage',
        loaded: () => {
            window.mixpanel = {
                initialized: true,
            };
        },
    });
};

export const pushEvent = (
    eventName: string,
    payload: Record<string, unknown> = {},
) => {
    pushEventAsync(eventName, payload);
};

const pushEventAsync = async (
    eventName: string,
    payload: Record<string, unknown> = {},
) => {
    const clevertap = await getClevertap();
    if (!clevertap?.event) {
        if (!isTest) {
            console.warn(errMsg);
        }
    } else {
        clevertap.event.push(eventName, payload);
    }

    if (window.mixpanel?.initialized) {
        const { default: mixpanel } = await getMixpanel();
        mixpanel.track(eventName, payload);
    }
};

const getUserTrackingData = (
    user: ClevertapUser,
    donee?: ClevertapDonee,
    appVersion: string | undefined = undefined,
) => {
    let extraPayload;
    try {
        const bankInfo = donee?.onboarding?.bankInfo;
        const bankAccountOnboardingStatus = bankInfo?.status || 'unchecked';
        const bankDocSubmitted = !!bankInfo?.fileSubmitted;
        const bankInfoSubmitted =
            bankAccountOnboardingStatus !== 'unchecked' && !bankDocSubmitted;

        const ein = donee?.onboarding?.ein;
        const taxInformationStatus = ein?.status || 'unchecked';
        const einTaxIdDocSubmitted = !!ein?.fileSubmitted;
        const einTaxIdInfoSubmitted =
            taxInformationStatus !== 'unchecked' && !einTaxIdDocSubmitted;

        const primaryRepresentative = donee?.onboarding?.primaryRepresentative;
        const primaryRepresentativeStatus =
            primaryRepresentative?.status || 'unchecked';
        const primaryRepSubmitted = primaryRepresentativeStatus !== 'unchecked';
        const primaryRepVerified = primaryRepresentativeStatus === 'verified';

        const appProfile = donee?.onboarding?.appProfile;
        const hasBannerImage = !!appProfile?.coverPhoto?.coverPhoto;
        const hasFaithLeadImg = !!appProfile?.faithLeader?.avatar;
        const hasLogoImg = !!appProfile?.organizationLogo?.logo;
        const hasProfileCompleted =
            hasBannerImage && hasFaithLeadImg && hasLogoImg;

        const signUpDate = donee?.signupDate
            ?.toISOString()
            ?.slice(0, 19)
            ?.replace('T', ' ');

        const MIDApprovedDate = donee?.onboarding?.midDateAdded
            ?.toISOString()
            ?.slice(0, 19)
            ?.replace('T', ' ');

        extraPayload = {
            hasMID: !!donee?.onboarding?.hasMid,
            hasDonations: !!donee?.lastDonationDate,
            verified: !!donee?.onboarding?.hasCompleted,
            orgType: donee?.type,
            hasBannerImage,
            hasFaithLeadImg,
            hasLogoImg,
            hasProfileCompleted,
            signUpDate,
            MIDApprovedDate,
            bankAccountOnboardingStatus,
            bankDocSubmitted,
            bankInfoSubmitted,
            taxInformationStatus,
            einTaxIdDocSubmitted,
            einTaxIdInfoSubmitted,
            primaryRepresentativeStatus,
            primaryRepSubmitted,
            primaryRepVerified,
        };
    } catch (e: any) {
        extraPayload = {};
        Logger.log(
            e?.toString() || 'getUserTrackingData error',
            {},
            LogLevel.ERROR,
        );
    }

    const result = {
        Site: {
            Identity: user.id?.toString(),
            Name: user.fullName,
            Email: user.username,
            //eslint-disable-next-line
            Donee_Official_id: user.officialId?.toString(),
            //eslint-disable-next-line
            Donee_id: user.doneeId?.toString(),
            appVersion,
            ...extraPayload,
        },
    };

    return result;
};

// todo: make this strictly typed with User type
/**
 * Call this method when user logs in
 */
export const onLogin = (
    user: ClevertapUser,
    appVersion: string | undefined = undefined,
) => {
    onLoginInternal(user, appVersion);
};

const onLoginInternal = async (
    user: ClevertapUser,
    appVersion: string | undefined = undefined,
) => {
    const clevertap = await getClevertap();
    if (!clevertap?.onUserLogin) {
        if (!isTest) {
            console.warn(errMsg);
        }
        return;
    }
    const trackData = getUserTrackingData(user, undefined, appVersion);
    clevertap.onUserLogin.push(trackData);

    if (window.mixpanel?.initialized) {
        const { default: mixpanel } = await getMixpanel();
        mixpanel.identify(user.id?.toString());
        mixpanel.people.set({
            $name: trackData.Site.Name,
            $email: trackData.Site.Email,
            doneeId: trackData.Site.Donee_id,
            appVersion: trackData.Site.appVersion,
        });
    }

    const { datadogRum } = await getDatadog();
    const { Identity, Name, Email, ...rest } = trackData.Site;
    datadogRum.setUser({
        id: Identity,
        email: Email,
        name: Name,
        ...rest,
    });
};

// todo: make this strictly typed with User type
/**
 * call this method to resume user session
 */
export const addProfile = (
    user: ClevertapUser,
    donee: ClevertapDonee,
    enabledFeatures: EnabledFeature[],
    appVersion: string | undefined = undefined,
) => {
    addProfileInternal(user, donee, enabledFeatures, appVersion);
};

const addProfileInternal = async (
    user: ClevertapUser,
    donee: ClevertapDonee,
    enabledFeatures: EnabledFeature[],
    appVersion: string | undefined = undefined,
) => {
    const trackData = getUserTrackingData(user, donee, appVersion);

    // Clevertap
    const clevertap = await getClevertap();
    if (!clevertap?.profile) {
        if (!isTest) {
            console.warn(errMsg);
        }
    } else {
        clevertap.profile.push(trackData);
    }

    // Mixpanel
    if (window.mixpanel?.initialized) {
        const { default: mixpanel } = await getMixpanel();
        mixpanel.identify(user.id?.toString());
        mixpanel.people.set({
            $name: trackData.Site.Name,
            $email: trackData.Site.Email,
            doneeId: trackData.Site.Donee_id,
            appVersion: trackData.Site.appVersion,
        });
    }

    // Datadog
    const { datadogRum } = await getDatadog();
    const { Identity, Name, Email, ...rest } = trackData.Site;
    datadogRum.setUser({
        id: Identity,
        email: Email,
        name: Name,
        ...rest,
    });

    const featuresValidLabels = enabledFeatures.map((f) => {
        let label = f.label || '';
        for (const c of featureFlagNameInvalidCharacters) {
            label = label.replaceAll(c, ' ');
        }

        // replace whitespaces with _
        // since datadog cant handle whitespaces
        label = label.replace(/ +/g, '_');

        return {
            label,
            isEnabled: f.isEnabled,
        };
    });

    featuresValidLabels.forEach((f) =>
        datadogRum.addFeatureFlagEvaluation(f.label, f.isEnabled),
    );

    // Appcues
    window.Appcues?.identify(user.id, donee);

    const fs = window.FS;
    if (fs) {
        fs.identify(donee.id.toString(), {
            displayName: `${donee.id} | ${donee.name}`,
            email: donee.email,
            // suffix (_bool, _str) is required by FullStory, not really sure why!
            // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Recording-custom-user-data
            onboardingCompleted_bool: donee.onboarding?.hasCompleted,
            organizationType_str: donee.type,
            signupDate_date: donee.signupDate
                ? new Date(donee.signupDate)
                : new Date(),
            city_str: donee.city,
            state_str: donee.state,
            country_str: donee.country,
            platform_str: 'studio',
        });
    }
};

export const onPageChange = () => {
    window.Appcues?.page();
};

export const onLogOut = () => {
    onLogOutAsync();
};

const onLogOutAsync = async () => {
    if (window.mixpanel?.initialized) {
        const { default: mixpanel } = await getMixpanel();
        mixpanel.reset();
    }

    const { datadogRum } = await getDatadog();
    datadogRum.clearUser();
};
