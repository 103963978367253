import { IApiHandler } from './IApiHandler';
import {
    AuthService,
    CommonService,
    DoneesService,
    EnvelopesService,
    IAuthService,
    ICommonService,
    IDoneeService,
    IEnvelopesService,
    IUsersService,
    UsersService,
} from './resources';

export class ApiHandler implements IApiHandler {
    private static _instance: ApiHandler;
    public auth: IAuthService;
    public users: IUsersService;
    public donees: IDoneeService;
    public common: ICommonService;
    public envelopes: IEnvelopesService;

    constructor() {
        this.auth = new AuthService();
        this.users = new UsersService();
        this.donees = new DoneesService();
        this.common = new CommonService();
        this.envelopes = new EnvelopesService();
    }

    public static get instance(): ApiHandler {
        if (!ApiHandler._instance) {
            ApiHandler._instance = new ApiHandler();
        }
        return ApiHandler._instance;
    }
}
